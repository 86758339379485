<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" lg="8" md="8" sm="12">
        <v-container fluid>
          <v-row class="justify-space-between" align="center" no-gutters>
            <v-col cols="5">
              <p class="title font-weight-bold ma-0">Parking Violations</p>
            </v-col>
            <v-col cols="7">
              <v-row no-gutters>
                <v-col lg="8" sm="8" md="8">
                  <span class="float-right">
                    <CreateViolationType
                      @refreshTypeComponent="refreshTypeComponent"
                      :key="createViolationTypeKey"
                    ></CreateViolationType>
                  </span>
                </v-col>
                <v-col lg="4" sm="4" md="4">
                  <span class="float-right">
                    <CreateViolation
                      @refreshComponent="refreshComponent"
                      :isEditViolation="false"
                      :key="createViolationKey"
                    ></CreateViolation>
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>

        <v-container fluid mt-0 mb-0 pt-0 pb-0>
          <v-row>
            <v-col cols="12" lg="2" md="2" sm="4" xs="12" class="pa-0 pl-3">
              <v-text-field
                v-model="searchTicketNumber"
                label="Ticket Number"
                append-icon="search"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="2" md="2" sm="4" xs="12" class="pa-0 pl-3">
              <v-text-field
                v-model="searchLicensePlate"
                label="License Plate"
                append-icon="search"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="2" md="2" sm="4" xs="12" class="pa-0 pl-3">
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="searchDateIssued"
                    dense
                    outlined
                    clearable
                    label="Date of Issue"
                    persistent-hint
                    append-icon="event"
                    readonly
                    v-on="on"
                    @click:clear="searchDateIssued = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchDateIssued"
                  :color="getColorScheme.darkColor"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
        <v-container fluid pt-0 pb-0>
          <v-row>
            <v-col
              cols="12"
              lg="2"
              md="2"
              sm="12"
              xs="12"
              class="pa-0 pl-3 subtitle-1 font-weight-bold"
            >
              List of Violations
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="12" xs="12" class="pa-0 pl-3">
              <v-switch
                dense
                v-model="includePaidViolations"
                class="mt-0 mb-0"
                @click="editViolationKey++"
                :color="getColorScheme.darkColor"
                label="Include Paid Parking Violations"
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
        <v-container fluid style="max-height: 68vh" class="overflow-y-auto">
          <v-row no-gutters>
            <v-col>
              <v-expansion-panels
                focusable
                accordion
                hover
                tile
                v-model="openPanelIndex"
              >
                <v-expansion-panel
                  v-for="(violation, i) in filteredViolations"
                  :key="i"
                >
                  <v-expansion-panel-header
                    class="pa-0"
                    disable-icon-rotate
                    style="font-size: 0.9em;"
                  >
                    <v-row align="center" no-gutters>
                      <v-col
                        class="pl-6"
                        cols="12"
                        lg="3"
                        md="3"
                        sm="3"
                        xs="12"
                      >
                        <Strong>Violation #:</Strong>
                        {{ violation.ticketNumber }}
                      </v-col>
                      <v-col
                        class="pl-6"
                        cols="12"
                        lg="5"
                        md="5"
                        sm="5"
                        xs="12"
                      >
                        <Strong>Violation Date:</Strong>
                        {{
                          $moment(violation.dateIssued).format("MMM Do YYYY")
                        }}, {{ violation.timeIssued }}
                      </v-col>
                      <v-col
                        class="pl-6"
                        cols="12"
                        lg="4"
                        md="4"
                        sm="4"
                        xs="12"
                      >
                        <v-icon :color="getWarningColor(violation.isWarning)">
                          info
                        </v-icon>
                        <span class="pl-1" v-if="violation.isWarning">
                          Warning
                        </span>
                        <span class="pl-1" v-else>Violation</span>
                      </v-col>
                    </v-row>
                    <template v-slot:actions>
                      <CreateViolation
                        :violationEdit="violation"
                        :isEditViolation="true"
                        :key="editViolationKey"
                      ></CreateViolation>
                      <v-icon
                        class="mr-2"
                        color="pink"
                        small
                        @click.native="
                          confirmDeleteViolation(
                            $event,
                            violation._id,
                            violation.ticketNumber
                          )
                        "
                        >mdi-delete
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row dense>
                      <v-col class="pl-0 pb-0">
                        <v-card
                          class="mx-auto"
                          :color="getColorScheme.lightColor"
                          height="100%"
                        >
                          <v-card-text class="pb-0" style="font-size: 0.8em;">
                            <v-row no-gutters>
                              <v-col cols="12" lg="4" md="4" sm="12" xs="12">
                                <v-row no-gutters>
                                  <v-col cols="4">
                                    <b>Ticket Number:</b>
                                  </v-col>
                                  <v-col cols="8">
                                    {{ violation.ticketNumber }}
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col cols="12" lg="4" md="4" sm="12" xs="12">
                                <v-row no-gutters>
                                  <v-col cols="4">
                                    <b>Issuer:</b>
                                  </v-col>
                                  <v-col cols="8">
                                    {{ violation.issuer.fullname }}
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col cols="12" lg="4" md="4" sm="12" xs="12">
                                <v-row no-gutters>
                                  <v-col cols="4">
                                    <b>Date of Issue:</b>
                                  </v-col>
                                  <v-col cols="8">
                                    {{
                                      $moment(violation.dateIssued).format(
                                        "MMM Do YYYY"
                                      )
                                    }}, {{ violation.timeIssued }}
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                            <v-row no-gutters>
                              <v-col cols="12" lg="4" md="4" sm="12" xs="12">
                                <v-row no-gutters>
                                  <v-col cols="4">
                                    <b>License Plate:</b>
                                  </v-col>
                                  <v-col cols="8">
                                    {{ violation.licensePlate }}
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col cols="12" lg="4" md="4" sm="12" xs="12">
                                <v-row no-gutters>
                                  <v-col cols="4">
                                    <b>Plate Province:</b>
                                  </v-col>
                                  <v-col cols="8">
                                    {{ violation.plateProvince }}
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col cols="12" lg="4" md="4" sm="12" xs="12">
                                <v-row no-gutters>
                                  <v-col cols="4">
                                    <b>Marked At:</b>
                                  </v-col>
                                  <v-col cols="8">
                                    {{ violation.markedAt }}
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                            <v-row no-gutters>
                              <v-col cols="12" lg="4" md="4" sm="12" xs="12">
                                <v-row no-gutters>
                                  <v-col cols="4">
                                    <b>Vehicle Make:</b>
                                  </v-col>
                                  <v-col cols="8">
                                    {{ violation.vehicleMake }}
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col cols="12" lg="4" md="4" sm="12" xs="12">
                                <v-row no-gutters>
                                  <v-col cols="4">
                                    <b>Vehicle Color:</b>
                                  </v-col>
                                  <v-col cols="8">
                                    {{ violation.vehicleColor }}
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col cols="12" lg="4" md="4" sm="12" xs="12">
                                <v-row no-gutters>
                                  <v-col cols="4">
                                    <b>Registered Owner:</b>
                                  </v-col>
                                  <v-col cols="8">
                                    {{ violation.registeredOwner }}
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                            <v-row no-gutters>
                              <v-col cols="12" lg="4" md="4" sm="12" xs="12">
                                <v-row no-gutters>
                                  <v-col cols="4">
                                    <b>Location:</b>
                                  </v-col>
                                  <v-col cols="8">
                                    {{ violation.location.address }}
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                            <v-row no-gutters>
                              <v-col cols="12" lg="4" md="4" sm="12" xs="12">
                                <v-row no-gutters>
                                  <v-col cols="4">
                                    <b>Notes:</b>
                                  </v-col>
                                  <v-col cols="8">{{ violation.note }}</v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-divider></v-divider>
                              </v-col>
                            </v-row>
                            <v-row no-gutters>
                              <v-col cols="12" lg="8" md="8" sm="12" xs="12">
                                <v-row no-gutters>
                                  <v-col cols="2">
                                    <b>Violations:</b>
                                  </v-col>
                                  <v-col cols="10">
                                    <p
                                      class="pa-0 ma-0"
                                      v-for="(types,
                                      i) in violation.violationTypes.map(
                                        (t) => t.type
                                      )"
                                      :key="i"
                                    >
                                      {{ i + 1 + ") " }}{{ types }}
                                    </p>
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col cols="12" lg="4" md="4" sm="12" xs="12">
                                <v-row no-gutters>
                                  <v-col cols="4">
                                    <b>Early Amount:</b>
                                  </v-col>
                                  <v-col cols="8">
                                    {{ "$" + getEarlyAmt(violation) }}
                                  </v-col>
                                </v-row>
                                <v-row no-gutters>
                                  <v-col cols="4">
                                    <b>Set Fine Amount:</b>
                                  </v-col>
                                  <v-col cols="8">
                                    {{ "$" + getFineAmt(violation) }}
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
          <v-dialog v-model="dialogDeleteConfirm" persistent max-width="425">
            <v-card>
              <v-card-title class="headline">
                Delete Parking Violation?
              </v-card-title>
              <v-card-text>
                Are you sure you want to delete ticket #{{ deleteTicketNum }}?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue-grey"
                  raised
                  dark
                  small
                  @click="dialogDeleteConfirm = false"
                  >Cancel
                </v-btn>
                <v-btn
                  :color="getColorScheme.darkColor"
                  dark
                  small
                  raised
                  @click="deleteViolation(deleteViolationId)"
                  >Confirm
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
      </v-col>
      <v-col cols="12" lg="4" sm="12" md="4">
        <MapComponent
          :dataObj="filteredViolations"
          titleField="ticketNumber"
          statusField=""
          locationField="location"
          @setOpenPanelIndex="setOpenPanelIndex"
        ></MapComponent>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import CreateViolation from "./create-violation";
import CreateViolationType from "./create-violation-type";
import { mapActions, mapState, mapGetters } from "vuex";
import * as constants from "../../shared/constants";
import MapComponent from "../map/map-component";

export default {
  name: "violations",
  components: { CreateViolation, CreateViolationType, MapComponent },
  props: {
    openViolationId: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      dialog: false,
      menu1: false,
      dateTimeFormat: constants.DATE_TIME_FORMAT,
      createViolationKey: 1,
      editViolationKey: 1,
      createViolationTypeKey: 1,
      searchTicketNumber: "",
      searchLicensePlate: "",
      searchDateIssued: null,
      dialogDeleteConfirm: false,
      deleteViolationId: null,
      deleteTicketNum: null,
      openPanelIndex: -1,
      includePaidViolations: false,
    };
  },
  async created() {
    await this.getViolationsAction();

    // In case coming from global search
    if (this.openViolationId != undefined) {
      this.openPanelIndex = this.violations.findIndex(
        (violation) => violation.id == this.openViolationId
      );
      this.includePaidViolations = true;
    }

    await this.getVehicleMakesAction();
    await this.getViolationTypesAction();
  },
  methods: {
    ...mapActions([
      "getViolationsAction",
      "getVehicleMakesAction",
      "getViolationTypesAction",
      "deleteViolationAction",
    ]),
    getWarningColor(isWarning) {
      if (isWarning) {
        return "amber";
      } else {
        return "red";
      }
    },
    refreshComponent: function() {
      this.createViolationKey++;
      this.editViolationKey++;
    },
    refreshTypeComponent: function() {
      this.createViolationTypeKey++;
    },
    confirmDeleteViolation: function(e, deleteViolationId, deleteTicketNum) {
      e.cancelBubble = true;
      this.dialogDeleteConfirm = true;
      this.deleteViolationId = deleteViolationId;
      this.deleteTicketNum = deleteTicketNum;
    },
    async deleteViolation(deleteViolationId) {
      this.dialogDeleteConfirm = false;
      await this.deleteViolationAction(deleteViolationId);
    },
    getEarlyAmt(violation) {
      let totEarlyAmt = 0.0;
      violation.violationTypes.forEach(function(entry) {
        totEarlyAmt += entry.earlyPmtAmt;
      });
      // saving in violation obj to show payments on Edit violation
      violation.earlyPmtForViolation = totEarlyAmt;
      return totEarlyAmt;
    },
    getFineAmt(violation) {
      let totFineAmt = 0.0;
      violation.violationTypes.forEach(function(entry) {
        totFineAmt += entry.setFineAmt;
      });
      violation.finePmtForViolation = totFineAmt;
      return totFineAmt;
    },
    setOpenPanelIndex(openPanelIndex) {
      this.openPanelIndex = openPanelIndex;
    },
  },
  computed: {
    ...mapState(["violations"]),
    ...mapGetters(["getUser", "getColorScheme"]),
    filteredViolations: function() {
      if (this.searchDateIssued == null) {
        this.searchDateIssued = "";
      }
      let filteredViolations = this.violations.filter((violation) => {
        if (
          violation != null &&
          violation != undefined &&
          violation.ticketNumber
            .toUpperCase()
            .match(this.searchTicketNumber.toUpperCase()) &&
          violation.licensePlate
            .toUpperCase()
            .match(this.searchLicensePlate.toUpperCase()) &&
          violation.dateIssued.match(this.searchDateIssued)
        ) {
          if (this.includePaidViolations) {
            return violation;
          } else if (violation.isPaid == false) {
            return violation;
          }
        }
      });
      return filteredViolations;
    },
  },
};
</script>
