<template>
  <v-row justify="center" no-gutters>
    <v-col>
      <v-dialog v-model="dialog" persistent width="40vh">
        <template v-slot:activator="{ on }">
          <v-btn :color="getColorScheme.darkColor" small dark raised v-on="on">
            <v-icon class="mr-1" small>
              mdi-plus
            </v-icon>
            Parking Violation Type
          </v-btn>
        </template>
        <v-card>
          <v-card-title
            class="title white--text"
            :style="`background-color:${getColorScheme.darkColor};`"
            >New Violation Type
          </v-card-title>
          <v-card-text>
            <v-form ref="form">
              <v-row no-gutters v-if="errors.length">
                <v-col>
                  <strong>Please correct the following error(s):</strong>
                  <ul class="red--text">
                    <li v-for="(error, i) in errors" :key="i">{{ error }}</li>
                  </ul>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="pt-6">
                  <v-text-field
                    v-model="violationType.type"
                    label="Type"
                    dense
                    outlined
                    :rules="[(v) => !!v || 'Type is required.']"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-text-field
                    v-model="violationType.bylawNumber"
                    label="Bylaw Number"
                    dense
                    outlined
                    :rules="[(v) => !!v || 'Bylaw Number is required.']"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-text-field
                    v-model="violationType.earlyPmtAmt"
                    label="Early Payment Amount"
                    dense
                    outlined
                    type="number"
                    :rules="[(v) => !!v || 'Early Payment Amount is required.']"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-text-field
                    v-model="violationType.setFineAmt"
                    label="Set Fine Amount"
                    dense
                    outlined
                    type="number"
                    :rules="[(v) => !!v || 'Set Fine Amount is required.']"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue-grey"
                      small
                      raised
                      dark
                      @click="cancelViolationType"
                      >Cancel
                    </v-btn>
                    <v-btn
                      :color="getColorScheme.darkColor"
                      small
                      raised
                      dark
                      @click="saveViolationType"
                      >Submit
                    </v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "create-violation-type",
  data() {
    return {
      violationType: {},
      dialog: false,
      errors: [],
    };
  },
  async created() {
    this.violationType = {
      type: "",
      bylawNumber: "",
      earlyPmtAmt: "",
      setFineAmt: "",
    };
  },
  computed: {
    ...mapGetters(["getViolationTypes", "getColorScheme"]),
  },
  methods: {
    ...mapActions(["addViolationTypeAction"]),
    cancelViolationType() {
      this.dialog = false;
      this.$emit("refreshTypeComponent", true);
    },
    async saveViolationType() {
      if (!this.isFormValid()) {
        return;
      }
      //saving violation type
      await this.addViolationTypeAction(this.violationType);
      this.dialog = false;
      this.$emit("refreshTypeComponent", true);
    },
    isFormValid() {
      let isValid = true;
      this.errors = [];
      if (
        this.violationType.type === "" ||
        this.violationType.type.trim() === ""
      ) {
        this.errors.push("Type is required.");
      }
      if (
        this.violationType.bylawNumber === "" ||
        this.violationType.bylawNumber.trim() === ""
      ) {
        this.errors.push("Bylaw Number is required.");
      }
      if (
        this.violationType.earlyPmtAmt === "" ||
        this.violationType.earlyPmtAmt.trim() === ""
      ) {
        this.errors.push("Early Payment Amount is required.");
      }
      if (
        this.violationType.setFineAmt === "" ||
        this.violationType.setFineAmt.trim() === ""
      ) {
        this.errors.push("Set Fine Amount is required.");
      }

      if (this.errors.length > 0) {
        isValid = false;
      }
      return isValid;
    },
  },
};
</script>
