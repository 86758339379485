<template>
  <v-row justify="center" no-gutters>
    <v-col>
      <v-dialog v-model="dialog" persistent width="80vh">
        <template v-slot:activator="{ on }">
          <v-icon
            v-if="isEditViolation"
            small
            v-on="on"
            class="mr-2"
            color="teal"
          >
            mdi-pencil
          </v-icon>
          <v-btn
            v-else
            :color="getColorScheme.darkColor"
            small
            dark
            raised
            v-on="on"
          >
            <v-icon class="mr-1" small>
              mdi-plus
            </v-icon>
            Parking Violation
          </v-btn>
        </template>
        <v-card>
          <v-card-title
            v-if="isEditViolation"
            class="title white--text"
            :style="`background-color:${getColorScheme.darkColor};`"
            >Edit Parking Violation
          </v-card-title>
          <v-card-title
            v-else
            class="title white--text"
            :style="`background-color:${getColorScheme.darkColor};`"
            >New Parking Violation
          </v-card-title>
          <v-card-text>
            <v-form ref="form">
              <v-row no-gutters v-if="errors.length">
                <v-col>
                  <strong>Please correct the following error(s):</strong>
                  <ul class="red--text">
                    <li v-for="(error, i) in errors" :key="i">{{ error }}</li>
                  </ul>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="4">
                  <v-switch
                    v-model="violation.isWarning"
                    label="Is Warning Only"
                    dense
                    outlined
                    :color="getColorScheme.darkColor"
                  ></v-switch>
                </v-col>
                <v-col cols="4" class="pt-4 pl-2 pr-2">
                  <v-text-field
                    v-model="violation.ticketNumber"
                    label="Ticket Number"
                    dense
                    outlined
                    :rules="[(v) => !!v || 'Ticket Number is required.']"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" class="pt-4 pl-2">
                  <v-combobox
                    v-model="violation.issuer"
                    :items="getUserList"
                    item-value="fullname"
                    item-text="fullname"
                    label="Issuer"
                    dense
                    outlined
                    :rules="[(v) => !!v || 'Issuer is required.']"
                  ></v-combobox>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="4" class="pr-2">
                  <v-text-field
                    v-model="violation.licensePlate"
                    label="License Plate"
                    dense
                    outlined
                    :rules="[(v) => !!v || 'License Plate is required.']"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" class="pl-2 pr-2">
                  <v-select
                    v-model="violation.plateProvince"
                    :items="provinceList"
                    label="Plate Province"
                    dense
                    outlined
                    height="32"
                    item-value="name"
                    item-text="name"
                  ></v-select>
                </v-col>
                <v-col cols="4" class="pl-2">
                  <v-text-field
                    v-model="violation.registeredOwner"
                    label="Registered Owner"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="4" class="pr-2">
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="violation.dateIssued"
                        dense
                        outlined
                        label="Date of Issue"
                        append-icon="event"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="violation.dateIssued = null"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="violation.dateIssued"
                      :color="getColorScheme.darkColor"
                      @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="4" class="pl-2 pr-2">
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="violation.timeIssued"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="violation.timeIssued"
                        label="Time Issued"
                        readonly
                        dense
                        outlined
                        allowed-seconds="false"
                        append-icon="access_time"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="violation.timeIssued"
                      full-width
                      v-if="menu2"
                      scrollable
                      :color="getColorScheme.darkColor"
                      :ampm-in-title="true"
                      @click:minute="$refs.menu2.save(violation.timeIssued)"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
                <v-col cols="4" class="pl-2">
                  <v-menu
                    ref="menu3"
                    v-model="menu3"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="violation.markedAt"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="violation.markedAt"
                        label="Marked At"
                        readonly
                        dense
                        outlined
                        allowed-seconds="false"
                        append-icon="access_time"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="violation.markedAt"
                      full-width
                      v-if="menu3"
                      scrollable
                      :color="getColorScheme.darkColor"
                      :ampm-in-title="true"
                      @click:minute="$refs.menu3.save(violation.markedAt)"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="4" class="pr-2">
                  <v-select
                    v-model="violation.vehicleMake"
                    :items="getVehicleMakes"
                    label="Vehicle Make"
                    dense
                    height="32"
                    outlined
                    item-value="name"
                    item-text="name"
                  ></v-select>
                </v-col>
                <v-col cols="4" class="pl-2 pr-2">
                  <v-text-field
                    v-model="violation.vehicleColor"
                    label="Vehicle Color"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="4" class="pl-2">
                  <google-autocomplete
                    placeholder=" Location"
                    @place_changed="setViolationLocation"
                    class="violation-location"
                    :value="violation.location.address"
                    v-on:change="onLocationChange"
                  >
                  </google-autocomplete>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="8" class="pr-2">
                  <v-select
                    v-model="violation.violationTypes"
                    :items="getViolationTypes"
                    label="Select Violation(s)"
                    multiple
                    small-chips
                    dense
                    outlined
                    item-value="_id"
                    :item-text="
                      (item) => item.type + ' [' + item.bylawNumber + ']'
                    "
                    v-on:change="selectViolation($event)"
                    :rules="[(v) => !!v || 'Select Violation(s) is required.']"
                  ></v-select>
                </v-col>
                <v-col
                  cols="4"
                  class="pl-2"
                  v-if="violationSelected"
                  style="font-size: 0.9em;"
                >
                  <v-row no-gutters align="center">
                    <v-col cols="2">
                      <strong>Totals</strong>
                    </v-col>
                    <v-col>
                      <v-row no-gutters>
                        <v-col align="right" class="pr-1" cols="8"
                          ><strong>Early Amount: </strong>
                        </v-col>
                        <v-col align="left"> {{ "$" + earlyPayment }}</v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col align="right" class="pr-1" cols="8">
                          <strong>Set Fine Amount: </strong>
                        </v-col>
                        <v-col align="left">
                          {{ "$" + setFinePayment }}
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="8" class="pr-2 mb-0">
                  <v-textarea
                    v-model="violation.note"
                    label="Notes"
                    dense
                    outlined
                    rows="2"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row v-if="isEditViolation">
                <v-col cols="4">
                  <v-row no-gutters>
                    <v-col cols="4" class="d-flex align-center pb-5">
                      <v-checkbox
                        dense
                        hide-details
                        class="ma-0 pa-0"
                        :color="getColorScheme.darkColor"
                        v-model="violation.isPaid"
                      ></v-checkbox>
                      <span class="caption">Is Paid</span>
                    </v-col>
                    <v-col cols="8" class="pl-2 pr-2" v-if="violation.isPaid">
                      <v-menu
                        ref="menu4"
                        v-model="menu4"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="violation.paidDate"
                            dense
                            outlined
                            label="Paid Date"
                            append-icon="event"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            @click:clear="violation.paidDate = null"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="violation.paidDate"
                          :color="getColorScheme.darkColor"
                          @input="menu4 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="float-right">
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      v-if="isEditViolation"
                      small
                      raised
                      color="blue-grey"
                      dark
                      @click="
                        dialog = false;
                        resetEditViolation();
                      "
                      >Cancel
                    </v-btn>
                    <v-btn
                      v-else
                      small
                      raised
                      color="blue-grey"
                      dark
                      @click="cancelViolation"
                      >Cancel
                    </v-btn>
                    <v-btn
                      v-if="isEditViolation"
                      :color="getColorScheme.darkColor"
                      small
                      raised
                      dark
                      @click="editViolation"
                      >Save
                    </v-btn>
                    <v-btn
                      v-else
                      :color="getColorScheme.darkColor"
                      small
                      raised
                      dark
                      @click="saveViolation"
                      >Submit
                    </v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { gmapApi } from "vue2-google-maps";
import * as commonjson from "../../shared/common-json";

export default {
  name: "create-violation",
  props: {
    violationEdit: {
      type: Object,
      required: false,
    },
    isEditViolation: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      violation: {},
      dialog: false,
      place: null,
      menu1: "",
      menu2: "",
      menu3: "",
      menu4: "",
      provinceList: commonjson.ProvinceList,
      earlyPayment: 0.0,
      setFinePayment: 0.0,
      errors: [],
    };
  },
  async created() {
    if (this.isEditViolation) {
      this.resetEditViolation();
    } else {
      this.violation = {
        isWarning: false,
        ticketNumber: "",
        licensePlate: "",
        vehicleMake: "",
        vehicleColor: "",
        registeredOwner: "",
        note: "",
        location: {},
        dateIssued: new Date().toISOString().substr(0, 10),
        timeIssued: new Date().getHours() + ":" + new Date().getMinutes(),
        markedAt: new Date().getHours() + ":" + new Date().getMinutes(),
        plateProvince: "Saskatchewan",
        isPaid: false,
      };
    }
  },
  computed: {
    ...mapGetters([
      "getViolationTypes",
      "getVehicleMakes",
      "getUserList",
      "getError",
      "getColorScheme",
    ]),
    google: gmapApi,
    violationSelected: function() {
      return this.earlyPayment > 0 ? true : false;
    },
  },
  methods: {
    ...mapActions(["addViolationAction", "editViolationAction"]),
    selectViolation: function(e) {
      e.cancelBubble = true;
      let selectedViolationsIds = [];
      for (let i = 0; i < e.length; i++) {
        selectedViolationsIds.push(e[i]);
      }
      const totalEarlyAmt = this.getViolationTypes.reduce((total, type) => {
        if (selectedViolationsIds.includes(type.id)) {
          total += type.earlyPmtAmt;
        }
        return total;
      }, 0);
      const totalFineAmt = this.getViolationTypes.reduce((total, type) => {
        if (selectedViolationsIds.includes(type.id)) {
          total += type.setFineAmt;
        }
        return total;
      }, 0);
      this.earlyPayment = totalEarlyAmt;
      this.setFinePayment = totalFineAmt;
    },
    setViolationLocation(place) {
      let newObj = {
        position: {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        },
        address: place.formatted_address,
      };
      this.violation.location = newObj;
    },
    onLocationChange() {
      if (this.isEditViolation) {
        this.violation.location = {};
      }
    },
    cancelViolation() {
      this.dialog = false;
      this.$emit("refreshComponent", true);
    },
    resetEditViolation() {
      this.violation = {
        _id: this.violationEdit._id,
        ticketNumber: this.violationEdit.ticketNumber,
        issuer: this.violationEdit.issuer,
        licensePlate: this.violationEdit.licensePlate,
        plateProvince: this.violationEdit.plateProvince,
        dateIssued: this.violationEdit.dateIssued,
        timeIssued: this.violationEdit.timeIssued,
        vehicleMake: this.violationEdit.vehicleMake,
        vehicleColor: this.violationEdit.vehicleColor,
        markedAt: this.violationEdit.markedAt,
        registeredOwner: this.violationEdit.registeredOwner,
        isWarning: this.violationEdit.isWarning,
        note: this.violationEdit.note,
        violationTypes: this.violationEdit.violationTypes,
        location: this.violationEdit.location,
        isPaid: this.violationEdit.isPaid,
        paidDate:
          this.violationEdit.paidDate == undefined
            ? new Date().toISOString().substr(0, 10)
            : this.violationEdit.paidDate,
      };

      this.earlyPayment = this.violationEdit.earlyPmtForViolation;
      this.setFinePayment = this.violationEdit.finePmtForViolation;
    },
    async saveViolation() {
      if (!this.isFormValid()) {
        return;
      }
      await this.addViolationAction(this.violation);
      //in case of error
      if (this.getError != "") {
        this.errors.push(this.getError);
        this.$store.state.error = "";
      } else {
        this.dialog = false;
        this.$emit("refreshComponent", true);
      }
    },
    async editViolation() {
      if (!this.isFormValid()) {
        return;
      }
      this.dialog = false;
      await this.editViolationAction(this.violation);
      this.$emit("refreshComponent", true);
    },
    isFormValid() {
      let isValid = true;
      this.errors = [];
      if (
        this.violation.ticketNumber === "" ||
        this.violation.ticketNumber.trim() === ""
      ) {
        this.errors.push("Ticket Number is required.");
      }
      if (this.violation.issuer == undefined || this.violation.issuer == "") {
        this.errors.push("Issuer is required.");
      }
      if (
        this.violation.licensePlate == "" ||
        this.violation.licensePlate.trim() === ""
      ) {
        this.errors.push("License Plate is required.");
      }
      if (
        this.violation.violationTypes == undefined ||
        this.violation.violationTypes == ""
      ) {
        this.errors.push("Select Violation(s) is required.");
      }

      if (this.errors.length > 0) {
        isValid = false;
      }
      return isValid;
    },
  },
};
</script>
<style scoped>
.violation-location {
  height: 32px;
  width: 100%;
  outline: none;
  border: 1px solid #808b96;
  border-radius: 4px;
  font-size: 0.9em;
  margin-bottom: 1%;
}
</style>
